export default {
  // Global

  // Neutral, light mode
  neutral7: "var(--color-neutral-7)",
  neutral12: "var(--color-neutral-12)",
  neutral15: "var(--color-neutral-15)",
  neutral17: "var(--color-neutral-17)",
  neutral19: "var(--color-neutral-19)",
  neutral24: "var(--color-neutral-24)",
  neutral32: "var(--color-neutral-32)",
  neutral45: "var(--color-neutral-45)",
  neutral50: "var(--color-neutral-50)",
  neutral62: "var(--color-neutral-62)",
  neutral68: "var(--color-neutral-68)",
  neutral81: "var(--color-neutral-81)",
  neutral88: "var(--color-neutral-88)",
  neutral93: "var(--color-neutral-93)",
  neutral95: "var(--color-neutral-95)",
  neutral97: "var(--color-neutral-97)",
  neutral98: "var(--color-neutral-98)",

  // Interaction
  interaction47: "var(--color-interaction-47)",
  interaction50: "var(--color-interaction-50)",
  interaction55: "var(--color-interaction-55)",
  interaction66: "var(--color-interaction-66)",
  interaction91: "var(--color-interaction-91)",
  interaction93: "var(--color-interaction-93)",

  // Alerts
  alertSuccess41: "var(--color-alert-success-41)",
  alertWarning58: "var(--color-alert-warning-58)",
  alertError59: "var(--color-alert-error-59)",
  alertInfo48: "var(--color-alert-info-48)",

  // Status
  statusCreate23: "var(--color-status-create-23)",
  statusCreate27: "var(--color-status-create-27)",
  statusCreate35: "var(--color-status-create-35)",
  statusCreate91: "var(--color-status-create-91)",
  statusCreate97: "var(--color-status-create-97)",
  statusCreateBase: "var(--color-status-create-base)",
  statusCreateControls: "var(--color-status-create-controls)",

  statusSuccessFill: "var(--color-status-success-fill)",
  statusSuccessFillInverted: "var(--color-status-success-fill-inverted)",
  statusSuccessSecondary: "var(--color-status-success-secondary)",
  statusSuccessText: "var(--color-status-success-text)",
  statusSuccessTextInverted: "var(--color-status-success-text-inverted)",

  statusInfoFill: "var(--color-status-info-fill)",

  statusWarning49: "var(--color-status-warning-49)",
  statusWarning55: "var(--color-status-warning-55)",
  statusWarning63: "var(--color-status-warning-63)",
  statusWarning90: "var(--color-status-warning-90)",
  statusWarning94: "var(--color-status-warning-94)",
  statusWarning97: "var(--color-status-warning-97)",
  statusWarningText: "var(--color-status-warning-text)",

  statusDelete43: "var(--color-status-delete-43)",
  statusDelete45: "var(--color-status-delete-45)",
  statusDelete47: "var(--color-status-delete-47)",
  statusDelete93: "var(--color-status-delete-93)",
  statusDelete97: "var(--color-status-delete-97)",

  // Tags
  tagAquaBg: "var(--color-tag-aqua-bg)",
  tagAquaIcon: "var(--color-tag-aqua-icon)",
  tagAquaText: "var(--color-tag-aqua-text)",

  tagBlueBg: "var(--color-tag-blue-bg)",
  tagBlueIcon: "var(--color-tag-blue-icon)",
  tagBlueText: "var(--color-tag-blue-text)",

  tagGrayBg: "var(--color-tag-gray-bg)",
  tagGrayIcon: "var(--color-tag-gray-icon)",
  tagGrayText: "var(--color-tag-gray-text)",

  tagGreenBg: "var(--color-tag-green-bg)",
  tagGreenText: "var(--color-tag-green-text)",
  tagGreenIcon: "var(--color-tag-green-icon)",

  tagOrangeBg: "var(--color-tag-orange-bg)",
  tagOrangeText: "var(--color-tag-orange-text)",
  tagOrangeIcon: "var(--color-tag-orange-icon)",

  tagRedBg: "var(--color-tag-red-bg)",
  tagRedIcon: "var(--color-tag-red-icon)",
  tagRedText: "var(--color-tag-red-text)",

  tagYellow: "var(--color-tag-yellow-bg)",
  tagYellowIcon: "var(--color-tag-yellow-icon)",
  tagYellowText: "var(--color-tag-yellow-text)",

  // Products - People
  peopleBrand24: "var(--color-people-brand-24)",
  peopleBrand31: "var(--color-people-brand-31)",
  peopleBrand41: "var(--color-people-brand-41)",
  peopleBrand51: "var(--color-people-brand-51)",
  peopleBrand54: "var(--color-people-brand-54)",
  peopleBrand57: "var(--color-people-brand-57)",
  peopleBrand65: "var(--color-people-brand-65)",
  peopleBrand71: "var(--color-people-brand-71)",
  peopleBrand81: "var(--color-people-brand-81)",
  peopleBrand91: "var(--color-people-brand-91)",
  peopleBrand94: "var(--color-people-brand-94)",
  peopleBrandGradient: "var(--color-people-brand-gradient)",

  // All products
  productCalendar: "var(--color-product-calendar)",
  productCheckIns: "var(--color-product-check-ins)",
  productGiving: "var(--color-product-giving)",
  productGroups: "var(--color-product-groups)",
  productHome: "var(--color-product-home)",
  productPeople: "var(--color-product-people)",
  productPublishing: "var(--color-product-publishing)",
  productRegistrations: "var(--color-product-registrations)",
  productServices: "var(--color-product-services)",

  // People specific

  // Basic colors
  accentColor: "var(--accent-color)",
  base0: "var(--base0)",
  base1: "var(--base1)",
  base2: "var(--base2)",
  base3: "var(--base3)",
  baseColor: "var(--base-color)",
  baseFontColor: "var(--base-font-color)",
  baseHeadingColor: "var(--base-heading-color)",
  bgColor: "var(--bgColor)",
  borderColor: "var(--border-color)",
  dangerColor: "var(--danger-color)",
  primaryBtnColor: "var(--primary-btn-color)",
  primaryBtnFocusColor: "var(--primary-btn-focus-color)",
  sidebarBgColor: "var(--sidebar-bg-color)",
  successColor: "var(--success-color)",
  warningColor: "var(--warning-color)",
  warningOrange: "var(--warning-orange)",
  warningOrangeLight: "var(--warning-orange-light)",
  warningTextColor: "var(--warning-text-color)",

  // Component aliases
  blankStateHeadingColor: "var(--blank-state-heading-color)",

  // Tapestry-React-specific aliases
  error: "var(--danger-color)",
  highlight: "var(--glacier)",
  linkBackground: "var(--bgColor)",
  primary: "var(--color-product-people)",
  primaryDark: "var(--color-people-brand-51)",
  primaryDarker: "var(--color-people-brand-41)",
  primaryDarkest: "var(--color-people-brand-31)",
  primaryLight: "var(--color-people-brand-65)",
  primaryLighter: "var(--color-people-brand-81)",
  primaryLightest: "var(--color-people-brand-94)",
  separatorFocus: "var(--base-color)",
  separatorFocusSecondary: "var(--blue-mesa)",
  warning: "var(--warning-orange)",
  warningLight: "var(--warning-orange-light)",
  warningLighter: "var(--warning-orange-light)",

  // Blues palette
  blueberry: "var(--blueberry)",
  bluebird: "var(--bluebird)",
  cornflower: "var(--cornflower)",
  blueMesa: "var(--blue-mesa)",
  blueSkies: "var(--blue-skies)",
  raindrop: "var(--raindrop)",
  glacier: "var(--glacier)",
  ice: "var(--ice)",
  snow: "var(--snow)",
  whisper: "var(--whisper)",

  // Blue-grays palette
  royal: "var(--royal)",
  regentGray: "var(--regent-gray)",
  shipCove: "var(--ship-cove)",
  nepal: "var(--nepal)",
  rockBlue: "var(--rock-blue)",
  cadetBlue: "var(--cadet-blue)",
  ghost: "var(--ghost)",
  waterTemple: "var(--water-temple)",
  spartaGray: "var(--sparta-gray)",

  // Grays palette
  charcoal: "var(--charcoal)",
  doveGray: "var(--dove-gray)",
  shadow: "var(--shadow)",
  graphite: "var(--graphite)",
  pebble: "var(--pebble)",
  anchor: "var(--anchor)",
  ash: "var(--ash)",
  fossil: "var(--fossil)",
  iron: "var(--iron)",
  cloud: "var(--cloud)",
  mist: "var(--mist)",
  athensGray: "var(--athens-gray)",
}
